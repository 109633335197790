import React from 'react';
import ContactForm from '../components/ContactForm';


const notify = () => {
	// 350 x 420
	// 332 x 380
	return (
		<>
			<ContactForm />
		</>
	);
};

export default notify;