import React from 'react';
import styled from "styled-components";

const NotifyStyles = styled.div`
	margin:0 auto;
	display:flex;
	justify-content:center;
	iframe{
		border:none;
	}

`;
const ContactForm = () => {
	// 350 x 420
	// 332 x 380
	return (
		<NotifyStyles>
			<iframe width="350px" height="550px" src="https://cdn.forms-content.sg-form.com/cda779bf-6a9b-11eb-81ec-1e52b8d25c7b"/>
		</NotifyStyles>
	);
};

export default ContactForm;